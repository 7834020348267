/* Remove border from toggler */
.navbar-toggler {
  border: 0 !important;
}

.navbar-toggler:focus,
.navbar-toggler:active,
.navbar-toggler-icon:focus {
  outline: none !important;
  box-shadow: none !important;
  border: 0 !important;
}

/* Lines of the Toggler */
.toggler-icon {
  width: 30px !important;
  height: 3px;
  background-color: black;
  display: block;
  transition: all 0.2s;
}

/* Adds Space between the lines */
.middle-bar {
  margin: 5px auto;
}

/* State when navbar is opened (START) */
.navbar-toggler .top-bar {
  transform: rotate(45deg);
  transform-origin: 10% 10%;
}

.navbar-toggler .middle-bar {
  opacity: 0;
  filter: alpha(opacity=0);
}

.navbar-toggler .bottom-bar {
  transform: rotate(-45deg);
  transform-origin: 10% 90%;
}
/* State when navbar is opened (END) */

/* State when navbar is collapsed (START) */
.navbar-toggler.collapsed .top-bar {
  transform: rotate(0);
}

.navbar-toggler.collapsed .middle-bar {
  opacity: 1;
  filter: alpha(opacity=100);
}

.navbar-toggler.collapsed .bottom-bar {
  transform: rotate(0);
}
/* State when navbar is collapsed (END) */

/* Color of Toggler when collapsed */
.navbar-toggler.collapsed .toggler-icon {
  background-color: black;
}
.show > .dropdown-menu {
  max-height: 900px;
  visibility: visible;
}

.Logo-size {
  margin-top: 4px;
  height: 71px;
  width: auto;
}
.NavbarDraw {
  min-width: 100%;
  position: fixed;
  z-index: 1;
  background-color: white;
  height: 90px;
  display: flex;
  justify-content: center;
  top: 0;
}
.collabse-button {
  margin: 30px 0;
}

.mainclass {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

/*  input:checked ~ .DropDownList {
  max-height: 600px;
  transition: max-height 0.5s ease-in;
}*/

.remove-dot {
  list-style-type: none;
}
.active-page {
}

.transition {
  height: 390px !important;
}
.transition-list {
  height: 300px !important;

  border-bottom: 1px solid;
}
.NavbarDraw {
  transition: height 0.3s;
}

.active-page {
  background-color: #33323261;
  margin: auto;
  width: 96%;
}

.DropDownList2 {
  height: 0;
  padding: 0;
  overflow: hidden;
  list-style-type: none;

  position: absolute;
  transition: height 0.3s;

  min-width: 100%;
  left: 0;
  top: 90px;
  z-index: 100;
  background-color: white;
  overflow-y: overlay;
}
.DropDownList2 li {
  display: block;
  padding: 12px;
  text-align: center;
  text-decoration: none;
  color: black;
  transition: background 0.3s;
  white-space: nowrap;
}
.DropDownList2 li a {
  color: black;
  text-decoration: none;

  margin: auto;
  width: 96%;
}

.Horizontal-list {
  display: none;
}
@media screen and (max-width: 1000px) {
  .Logo-size {
    margin-top: 4px;
    height: 51px;
    width: auto;
  }
}
@media screen and (min-width: 1000px) {
  .active-page a {
    color: #152641;
  }

  .active-page {
  }

  .Horizontal-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    color: white;
    text-decoration: none;
  }
  .Horizontal-list {
    list-style-type: none;
    margin: 0;
    padding: 0;
    overflow: hidden;
    margin-top: auto;
    margin-bottom: auto;
  }

  .Horizontal-list li {
    float: left;
  }

  .Horizontal-list-elements:hover {
    color: white;
  }
}
.Horizontal-list-elements {
  padding: 0 20px;
  color: white;
  text-decoration: none;
  display: block;
}
.Horizontal-list-elements:hover {
  color: #152641;
}

/* 
side nav bar  */
.sidenav {
  height: 100%;
  width: 0;
  position: fixed;
  z-index: 1;
  top: 0;
  background-color: #111;
  overflow-x: hidden;
  transition: 0.5s;
  padding-top: 60px;
  overflow-y: auto;
}

.sidenav a {
  padding: 5px 10px;
  text-decoration: none;
  font-size: 25px;
  color: #818181;
  display: block;
  transition: 0.3s;
}

.sidenav a:hover {
  color: #f1f1f1;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 25px;
  font-size: 36px;
}

#main {
  transition: margin 0.5s;
  min-width: 300px;
}
