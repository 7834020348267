.squared-input-container label {
  display: block;
  margin-bottom: 7px;
  font-size: inherit;
  font-weight: lighter;
  min-width: 120px;
  margin-right: 5px;
  color: #374767;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  font-weight: bold;
  /* background-color: aqua; */
}
.squared-input-container {
  margin: 10px 0 !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.input-container {
  display: block;
  width: 100%;
  background-color: white;
  border: 1px solid #d7dbe0;
  border-radius: 3px;
}
.squared-input-container input {
  padding: 10px;
  border: 0px solid #d7dbe0;
  color: #5c5c5c;
  outline: 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  vertical-align: top;
  font-size: 1em;
  line-height: 1.25em;
  font-size: 15px;
}
.squared-input-container input::placeholder {
  color: gray;
  font-size: 15px;
  /* font-weight: 200; */
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}
.error-text {
  display: flex;
  justify-content: flex-end;
  color: #ff8787;
}
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
@media screen and (max-width: 300px) {
  .squared-input-container {
    display: block;
  }
}
.margin-content {
  margin: 3px;
}
