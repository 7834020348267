.table-button {
  font-size: 50px;
}
.dx-datagrid-filter-row
  .dx-editor-cell
  .dx-editor-with-menu
  .dx-placeholder::before,
.dx-datagrid-filter-row
  .dx-editor-cell
  .dx-editor-with-menu
  .dx-texteditor-input {
  padding: 0px 24px !important;
}
